import AccountCodeGroup from './AccountCodeGroup.vue'
import AccountCodeGroupCreate from './Create.vue'
import AccountCodeGroupEdit from './Create.vue'

export default [
    {
        path: '/accountCodeGroup',
        name: 'AccountCodeGroup',
        component: AccountCodeGroup
    },
    {
        path: '/accountCodeGroup/create',
        name: 'AccountCodeGroupCreate',
        component: AccountCodeGroupCreate
    },
    {
        path: '/accountCodeGroup/edit/:id',
        name: 'AccountCodeGroupEdit',
        component: AccountCodeGroupEdit
    },
]
